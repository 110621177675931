var site = site || {};
site.direction = site.direction || {};

(function ($) {
  Drupal.behaviors.contentBlockProductV3 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $carousels = $('.js-content-block-product-v3 .js-product-brief-carousel-items', context);

      $carousels.each(function () {
        var $this = $(this);
        var $arrowsPrev = $this.parent().find('.slick-prev');
        var $arrowsNext = $this.parent().find('.slick-next');
        var $dotsDiv = $this.parent().find('.carousel-dots');
        // autoplay settings
        var autoplay = $this.data('slidesAutoplay');

        if (typeof autoplay === 'undefined') {
          autoplay = false;
        } else {
          autoplay = true;
        }
        var settings = {
          rtl: site.direction.isRTL,
          appendDots: $dotsDiv,
          prevArrow: $arrowsPrev,
          nextArrow: $arrowsNext,
          autoplay: autoplay,
          autoplaySpeed: 5000,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true
        };

        // Init this carousel with our settings
        $this.slick(settings);
      });
    },
    attached: false
  };
})(jQuery);
